<template>
  <pz-form-flujo
      v-model="formulario_verificar_cliente['captcha']"
      :available-captcha="extraData.withCaptcha"
      :forward="handleSave"
      :backwards="showBack?handleBack:null"
  >
    <span
      v-show="showForm"
      slot="form_title"
    >
      {{ $t('forms_flujo.verificacion_cliente.title') }}
    </span>

    <el-form
      v-show="showForm"
      slot="formulario"
      :ref="reference"
      :model="formulario_verificar_cliente"
      status-icon
    >
      <el-col
        :xl="{span: 12,offset:6}"
        :lg="{span: 12,offset:6}"
        :md="{span: 12,offset:6}"
        :sm="{span: 24}"
        :xs="{span: 24}"
      >

      </el-col>
      <br>
      <el-col
        :span="24"
        class="center-block text-center"
      >
        <span style="color:#db996c;">
          {{ $t('forms_flujo.pz_simulators_template.warning_bnf') }}
        </span>
      </el-col>
    </el-form>

    <el-col
      v-show="showForm"
      slot="buttons"
      :span="24"
      class="center-block text-center"
    >
      <pz-back-button
        v-if="showBack"
        :back="handleBack"
      />
      <pz-captcha-button
        v-if="extraData.withCaptcha"
        v-model="formulario_verificar_cliente['captcha']"
        :forward="handleSave"
      />
      <pz-forward-button
        v-else
        v-model="formulario_verificar_cliente['captcha']"
        :forward="handleSave"
      />
    </el-col>
  </pz-form-flujo>
</template>

<script>
import PzForwardButton from "@/commons/forms/Buttons/PzForwardButton";
import PzBackButton from "@/commons/forms/Buttons/PzBackButton";
import baseVerificacionCliente from "./baseVerificacionCliente";
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import {isEmpty} from '@/commons/utils/functions';

export default {
  name: 'PzVerificacionHashCliente',
  components: {PzFormFlujo, PzBackButton, PzForwardButton},
  mixins: [baseVerificacionCliente],

  data() {
    return {
      formulario_verificar_cliente: {
        hash: '',
        identity: {}
      },
      defaultData: {},

      showForm: false
    }
  },

  mounted() {
    this.checkDocAvailability();
  },

  methods: {
    checkDocAvailability() {
      if (this.defaultData !== null ) {
        if (this.defaultData.hasOwnProperty('hash')  && !isEmpty(this.$route.params.tipodoc) ) {
          this.formulario_verificar_cliente = {
            hash: this.$route.params.tipodoc,
            identity: {
              document: 1,
              number: '33333'
            }
          };
            this.$store.dispatch('product/setFormProduct', {verify_route: true});
          this.handleSave();
        } else {
          this.showForm = true;
        }
      }
    }
  }
}
</script>
